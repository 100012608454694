export const useRolesCheck = (
	userRoles: string[],
	rolesToCheck: string[] = []
) => {
	const hasRole = (allowedRoles: string[]) => {
		return allowedRoles.some((role) => userRoles.includes(role));
	};

	return rolesToCheck.reduce((acc, role) => {
		acc[role] = hasRole([role]);
		return acc;
	}, {} as Record<string, boolean>);
};
