import React from "react";
import ReactDOM from "react-dom";
import { mergeStyles } from "@fluentui/react";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";

import { App } from "./App";
import { authInit, setupAxioInterceptor } from "./auth";

axios
	.get(`./config.json`)
	.then((res) => {
        const authProvider = authInit(res.data);
        setupAxioInterceptor(authProvider, res.data);
		axios.defaults.baseURL = res.data.backendUrl;

		// Inject some global styles
		mergeStyles({
			":global(body,html,#root)": {
				margin: 0,
				padding: 0,
				height: "100vh",
			},
		});

		const baseUrl = !res.data.baseUrl ? "/" : res.data.baseUrl;
		console.log("baseUrl: " + baseUrl);

		ReactDOM.render(
            <BrowserRouter basename={baseUrl}>
                <App authProvider={authProvider}/>
            </BrowserRouter>,
			document.getElementById("root")
		);
	})
	.catch((e) => {
		console.error(e);
		ReactDOM.render(
			<div className="critical-error">
				Unable to load app configuration, please contact app-admin for
				assistance.
			</div>,
			document.getElementById("root")
		);
	});

