import React, { useState, useEffect } from "react";

import {
	Dialog,
	DialogTrigger,
	DialogSurface,
	DialogTitle,
	DialogBody,
	DialogContent,
	Button,
	makeStyles,
} from "@fluentui/react-components";
import {
	bundleIcon,
	DocumentOnePageSparkleRegular,
	DocumentOnePageSparkleFilled,
	DismissRegular,
} from "@fluentui/react-icons";

const useStyles = makeStyles({
	dialogContent: {
		">.fui-DialogContent": {
			overflowY: "hidden",
		},
	},
});

function CvModal(props: any) {
	const styles = useStyles();
	const { selectedIntID, candidateAnalysis } = props;
	const [src, setSrc] = useState("");
	const [preview, setPreview] = useState("");
	const DocumentOnePageSparkle = bundleIcon(
		DocumentOnePageSparkleFilled,
		DocumentOnePageSparkleRegular
	);

	useEffect(() => {
		if (candidateAnalysis) {
			setSrc(candidateAnalysis.cvImgSrc);
			setPreview(candidateAnalysis.pdfUrl);
		}
	}, [candidateAnalysis]);

	return (
		<div>
			<Dialog modalType="non-modal">
				<DialogTrigger disableButtonEnhancement>
					<Button
						icon={<DocumentOnePageSparkle />}
						disabled={!candidateAnalysis || candidateAnalysis.pdfUrl === ""}
					>
						View CV
					</Button>
				</DialogTrigger>
				<DialogSurface>
					<DialogBody className={styles.dialogContent}>
						<DialogTitle
							action={
								<DialogTrigger action="close">
									<Button
										appearance="subtle"
										aria-label="close"
										icon={<DismissRegular />}
									/>
								</DialogTrigger>
							}
						></DialogTitle>
						{candidateAnalysis ? (
							<DialogContent>
								<iframe
									title="cv-preview"
									className="preview-pdf"
									src={preview}
								/>
							</DialogContent>
						) : (
							<div>Unable to show preview of candidate's CV</div>
						)}
					</DialogBody>
				</DialogSurface>
			</Dialog>
		</div>
	);
}
export default CvModal;
