import React from "react";

import { Button, Divider, SkeletonItem } from "@fluentui/react-components";

import { ChevronLeftFilled } from "@fluentui/react-icons";

import "../../styles/Candidates.css";

export default function CandidateLoadingSkeleton() {
  return (
    <>
      <div className="skeleton-general-container">
        <div className="skeleton-main-content-grid">
          <div className="skeleton-section-container">
            <div className="skeleton-top-bar">
              <div className="skeleton-action-button">
                <Button icon={<ChevronLeftFilled />} appearance="transparent">
                  Back to Search Result
                </Button>
              </div>
            </div>

            <div className="skeleton-details-container">
              <div className="skeleton-items-container">
                <SkeletonItem shape="rectangle" size={12} />
                <SkeletonItem shape="rectangle" size={12} />
                <SkeletonItem shape="rectangle" size={12} />
              </div>
            </div>

            <div className="skeleton-skills-status-container">
              <div className="skeleton-items-container">
                <SkeletonItem shape="rectangle" size={12} />
                <SkeletonItem shape="rectangle" size={12} />
                <SkeletonItem shape="rectangle" size={12} />
              </div>
            </div>
          </div>

          <div className="skeleton-side-section">
            <Divider vertical className="skeleton-divider-vertical" />
            <div className="skeleton-side-content-container"></div>
          </div>
        </div>
      </div>
    </>
  );
}
