import Logo from "../../components/Logo.js";
import "../../styles/LHSBar.css";
import { useState } from "react";
import { ViewCV } from "./Subitem.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function LHSB(props) {
  const [isSelected, setIsSelected] = useState(false);
  const [candidateId] = useState(props.id);
  const navigate = useNavigate();

  const close = () => {
    setIsSelected(false);
  };

  const axiosDownloadFile = (url, fileName) => {
    return axios({
      url,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const href = window.URL.createObjectURL(response.data);
        const anchorElement = document.createElement("a");
        anchorElement.href = href;
        anchorElement.download = fileName;
        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error("Error downloading file: ", error);
      });
  };

  const handleLogoClick = () => {
    navigate("/jobopenings");
  };

  const cvImage = props.src?.map((src, index) => (
    <img
      className="img-size"
      src={src}
      key={index}
      alt={`CV ${index}`}
      onClick={() => {
        setIsSelected(true);
      }}
    />
  ));

  return (
    <>
      <Logo onClick={handleLogoClick} />
      <div className="LHSBar-bg">
        <p className="fonts-size">{props.page}</p>
        <p className="fonts-size">{props.username}</p>
        <div className="img-block">{cvImage}</div>
        {props.src && (
          <button
            className="Download"
            onClick={() =>
              axiosDownloadFile(
                `/candidate/${candidateId}/cv`,
                `${props.username}_cv.pdf`
              )
            }
          >
            Download CV
          </button>
        )}
        <button className="Download" onClick={() => window.print()}>
          Print Analysis Report
        </button>
      </div>
      {isSelected && (
        <ViewCV viewimage={props.src} preview={props.preview} close={close} />
      )}
    </>
  );
}

export default LHSB;
