import { Link } from "react-router-dom";
import React from "react";

function Logo(props) {
	return (
		<div className="logo-bg">
			<Link to="/jobopenings" style={{ height: "80%", width: "100%" }}>
				<img
					src="JardinesLogoWithName.svg"
					alt="App-logo"
					style={{ height: "100%", width: "100%" }}
				/>
			</Link>
		</div>
	);
}

export default Logo;
