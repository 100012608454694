import React from "react";
import { useRoutes } from "react-router-dom";


import Layout from "../layout";
import Home from "../pages/Home/View";
import Candidates from "../pages/Candidates/View";
import JobOpenings from "../pages/JobOpenings/View";
import AnalysisDashboard from "../pages/Analysis/View";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Layout/>,
      children: [

        { path: "/", element: <Home /> },
        { path: "/candidates", element: <Candidates /> },
        { path: "/candidates/dashboard/:id", element: <AnalysisDashboard /> },
        { path: "/jobopenings", element: <JobOpenings /> },
      ],
    },
  ]);
}
