import React from "react";
import Topbar from "./topbar";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FluentProvider } from "@fluentui/react-components";
import { createDarkTheme, createLightTheme } from "@fluentui/react-components";
import type { BrandVariants, Theme } from "@fluentui/react-components";
import { createTheme, ThemeProvider } from "@fluentui/react";

const Aimbest: BrandVariants = {
  10: "#d8dce9",
  20: "#b6bdd4",
  30: "#98a1c0",
  40: "#7c87ab",
  50: "#636f97",
  60: "#4d5982",
  70: "#3a466e",
  80: "#293459",
  90: "#092b68",
  100: "#1b2546",
  110: "#151f3b",
  120: "#10182f",
  130: "#0c1224",
  140: "#070d18",
  150: "#04080d",
  160: "#010303",
};

const lightTheme: Theme = {
  ...createLightTheme(Aimbest),
};

const darkTheme: Theme = {
  ...createDarkTheme(Aimbest),
};

darkTheme.colorBrandForeground1 = Aimbest[110];
darkTheme.colorBrandForeground2 = Aimbest[120];

const aimbestTheme = createTheme({
  palette: {
    themePrimary: "#092b68",
    themeLighterAlt: "#d8dce9",
    themeLighter: "#b6bdd4",
    themeLight: "#98a1c0",
    themeTertiary: "#7c87ab",
    themeSecondary: "#636f97",
    themeDarkAlt: "#4d5982",
    themeDark: "#3a466e",
    themeDarker: "#293459",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#595959",
    neutralSecondary: "#373737",
    neutralSecondaryAlt: "#373737",
    neutralPrimaryAlt: "#2f2f2f",
    neutralPrimary: "#000000",
    neutralDark: "#151515",
    black: "#0b0b0b",
    white: "#ffffff",
  },
});

export default function Layout() {
  const location = useLocation();
  const noTopbar = location.pathname.startsWith("/candidates/dashboard/");

  return (
    <FluentProvider theme={lightTheme}>
      <ThemeProvider applyTo="body" theme={aimbestTheme}>
        <div>
          {!noTopbar && <Topbar />}
          <div className="outlet-wrapper">
            <Outlet />
          </div>
        </div>
      </ThemeProvider>
    </FluentProvider>
  );
}
