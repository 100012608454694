import axios from "axios";
import { LogLevel } from "@azure/msal-browser";
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-browser";


const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
const tenantId = process.env.REACT_APP_MSAL_TENANT_ID;
const clientId = process.env.REACT_APP_MSAL_CLIENT_ID || '';


export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    // navigateToLoginRequestUrl: true,
  },

};

// Define a function to initiate auth provider if loginProvider is enabled
// **Now only MSAL is available
export function authInit(config: any) {
  if (config.loginProvider === "disabled") {
    return undefined;
  }
  msalConfig.auth.clientId = config.msalClientId;
  msalConfig.auth.authority = `https://login.microsoftonline.com/${config.msalTenantId}`;
  msalConfig.auth.redirectUri = config.msalRedirectUri;
  const msalInstance = new PublicClientApplication(msalConfig);
  /**
   * To set an active account after the user signs in, register an event and listen to LOGIN_SUCCESS & LOGOUT_SUCCES. For more,
   * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }

    if (event.eventType === EventType.LOGOUT_SUCCESS) {
      if (msalInstance.getAllAccounts().length > 0) {
        msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
      }
    }

    if (event.eventType === EventType.LOGIN_FAILURE) {
      console.log(JSON.stringify(event));
    }
  });

  return msalInstance
};

// Function to acquire token from msal
const acquireToken = async (msalInstance: PublicClientApplication, config: any) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    const accessTokenRequest = {
      account: accounts[0],
      scopes: config.msalLoginScope.split(","),
    };

    try {
      const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // Fallback to interaction when silent call fails
        return msalInstance.acquireTokenPopup(accessTokenRequest).then((response) => {
          return response.accessToken;
        });
      }
      console.log(error);
      throw error;
    }
  }
  return null;
};

// Setup Axios Interceptor to inject access token
export const setupAxioInterceptor = (authProvider: any, config: any) => axios.interceptors.request.use(async (axiosConfig) => {
  try {
    if (authProvider) {
      const accessToken = await acquireToken(authProvider, config);
      axiosConfig.headers['Authorization'] = `Bearer ${accessToken}`;
    }

  } catch (error) {
    console.error('Error getting access token', error);
  }
  return axiosConfig;
}, (error) => {
  return Promise.reject(error);
});