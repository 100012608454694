export const formattedDate = (dateString: string): string => {
    const formattedDate = new Date(dateString).toLocaleDateString("en-HK", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
  
    return formattedDate;
  };
  