import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";
import "../../styles/AnalysisDashboard.css";

function CVRadarChart(props) {
  const renderColorfulLegendText = (value, entry) => {
    return <span className="RadarChart-legend-text">{value}</span>;
  };

  const formattedWords = (word) => {
    return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
  };

  const CustomizedAxisTick = ({ x, y, textAnchor, payload }) => {
    const words = payload.value.split(" ");

    return (
      <g transform={`translate(${x},${y})`}>
        {words.map((word, index) => (
          <text
            className="recharts-text recharts-polar-angle-axis-tick-value"
            key={index}
            x={0}
            y={0}
            dy={`${index * 16}px`}
            textAnchor={textAnchor}
          >
            {formattedWords(word)}
          </text>
        ))}
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="90%">
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={props.data}>
        <PolarGrid />
        <PolarAngleAxis
          dataKey="skillName"
          tickSize={18}
          tick={CustomizedAxisTick}
        />
        <PolarRadiusAxis
          angle={0}
          style={{ display: "none" }}
          domain={[0, 10]}
        />
        <Radar
          name="Requirement Score"
          dataKey="requirementScore"
          stroke="purple"
          fill="transparent"
          fillOpacity={1}
          strokeWidth={3}
        />
        <Radar
          name="Candidate Score"
          dataKey="candidateScore"
          stroke="darkorange"
          fill="transparent"
          fillOpacity={1}
          strokeWidth={3}
        />
        <Legend
          className="radar-chart-legend"
          formatter={renderColorfulLegendText}
          wrapperStyle={{ paddingTop: 20, width: "100%" }}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
}

export default CVRadarChart;
