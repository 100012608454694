function OpeningList(props) {
  const hasApplied =
    props.currentOpenings?.some((opening) => opening.isApplied) || false;

  if (props.currentOpenings !== undefined) {
    var currentOpenings = props.currentOpenings.map((currentOpening, index) => (
      <div
        className={props.isSelected === index ? "Selected" : "openinglist-body"}
        key={index}
        onClick={() => {
          props.handleSelect(index);
        }}
      >
        <div className="openinglist-left-text">
          {currentOpening.currentPost} {currentOpening.isApplied ? " *" : ""}
        </div>
        <div className="openinglist-right-text">
          <b>{currentOpening.score}</b>
        </div>
      </div>
    ));
  }

  return (
    <div className="openinglist-base">
      <div className="openinglist-header">
        <div className="openinglist-left-text">
          <b>{props.keyname}</b>
        </div>
        <div className="openinglist-right-text">
          <b>{props.item}</b>
        </div>
      </div>
      <div className="openinglist-body-base">
        {props.currentOpenings !== undefined ? currentOpenings : ""}
      </div>
      <div className="opening-list-footer">
        <p>* = Applied Position(s)</p>
      </div>
    </div>
  );
}

export default OpeningList;
