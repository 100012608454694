import * as React from "react";
import "./App.css";
import Router from "./routes";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate} from "@azure/msal-react";

// Initialize Fluent UI icons (call this once in your app)
initializeIcons();

const queryClient = new QueryClient();
type AppProps = {
	authProvider: IPublicClientApplication | undefined;
};

export const App = (props: AppProps) => {
	const { authProvider } = props;

	const content = !!authProvider ? (
		<MsalProvider instance={authProvider}>
			<MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
				<Router />
			</MsalAuthenticationTemplate>
		</MsalProvider>
	) : (
		<Router />
	)

	return (
		<QueryClientProvider client={queryClient}>
			{content}
		</QueryClientProvider>
	);
};
